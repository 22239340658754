import React, { Component } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import InputMet from '../Finance/InputMet';
import convertDateToDb, { converDateToMonthYear } from '../../../convertDateToDb'
import { ConstantsNames, RestUrls } from "../../../Urls";
import MonthSelector from '../Finance/MonthSelector';

export default class MissingKevaPays extends Component {

    constructor(props) {
        super(props)

        let myDate = new Date();

        //let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        //let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {

            socialWorker: '',
            date1: new Date(myDate.toString().replace(/-/g, '/')),
            //date2 : new Date(lastDay.toString().replace(/-/g, '/')),

            branch: '',
            company: '',

        }
    }

    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function () {
                //this.submitToProps();
            }
        );
    }

    handleSelect = (event) => {


        this.setState({ [event.target.name]: event.target.value }, function () {
            //console.log(this.state)
            //this.submitToProps();
        });

    }

    sendData = () => {

        const { month, year } = converDateToMonthYear(this.state.date1)

        var md5 = require('md5');

        //let query = '&rakaz=' + sendObj.socialWorker;
        let query = '&month=' + month;
        query = query + '&year=' + year;


        let link = ConstantsNames.base_url + '/KevaPayments/checkMissingKevaPaymentsForMonth?token=' + md5(RestUrls.Code) + query;
        //let link = ConstantsNames.base_url + '/ReportsPdf/makeCsvVisit?token=' + md5(RestUrls.Code) + query;

        window.open(link, '_blank');

        //console.log(link);
    }


    render() {
        return (
            <section className="ReportsSection animate__animated animate__fadeIn">

                <div className="row">

                    <div className="col-md-3 divBoostrap col-sm-12">
                        <MonthSelector text='מתאריך' as={'dateInput'} value={this.state.date1} placeholder='' name='date1' submit={this.submit} />
                    </div>


                    <div className="col-md-2 divBoostrap col-sm-12">
                        <Button style={{ width: '100%' }} onClick={() => this.sendData()} size="" variant="success" >שליחה</Button>
                    </div>

                </div>

            </section>
        )
    }
}
