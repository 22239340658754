export default function convertDateToDb(dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

    /* 2021-03-25 */

    let formatted_date = current_datetime.getFullYear() + '-' +
        (current_datetime.getMonth() + 1) + "-" +
        current_datetime.getDate();

    return formatted_date;
}

export function converDateToMonthYear(dateValue) {
    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/'));
    return { month: current_datetime.getMonth() + 1, year: current_datetime.getFullYear() }
}
